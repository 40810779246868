import request from '@/utils/request'

// 查询当前支付通道及支持银行
export function queryPayList() {

  return request({
    url: '/api/sale/sms/getPayChannel',
    method: 'post'
  })
}




