<template>
  <div class="test">
    <div class="el-row"><h3 class="title">选择开户银行</h3></div>
    <div class="el-row el-row-1" >
        <div class="el-col el-col-6" v-for="(bank,i) in list" :key="bank.bankNo">
          <div  @click="demo(i)" :class="[i === iFlag ? 'checked-div' : '' ,'grid-content', 'bg-purple']">
            <div class="el-col-3"><img class="img-1" v-if="bank.logoUrl" :src="bank.logoUrl"/><span class="font-common title">{{bank.bankName}}</span></div>
            <hr class="hr-1">
            <div class="el-col-1">
              <div class="el-col-2"><span class="font-common font-money">{{bank.singelLimit}}</span><span class="font-common font-norm">单笔限额</span></div>
              <div class="el-col-2"><span class="font-common font-money">{{bank.singeldayLimit}}</span><span class="font-common font-norm">单日限额</span></div>
            </div>

          </div>
        </div>
    </div>
    <div class="el-row el-row-2" ><button class = "button-css" @click="check()"><span class = "button-font">确认选择</span></button></div>
  </div>
</template>

<script>
import { queryPayList } from "@/api/chooseBank";
export default {
  name: "ChooseBank",
  data(){
    return {
      list :[],
      iFlag: null,
      selectedBankNo :null,
      selectedBankName :null
    }
  },
  components: {},
  mounted() {
    queryPayList({
    }).then((res) => {
      console.log(res);
      this.list = res.data;
    });
  },
  methods: {
    demo(i) {
     this.iFlag = i;
     this.selectedBankNo = this.list[i].bankNo;
     this.selectedBankName = this.list[i].bankName;
    },
    check(){
      console.log( this.selectedBankNo ,this.selectedBankName )
    }
  },
};
</script>

<style scoped>
.test {
  min-height: 600px;
}
.img-1{
  width: 24px;
  height: 24px;
  margin-right:20px ;
}
.el-row{
    position: relative;
    box-sizing: border-box;
  }
.el-row-1{
  margin:20px 20px;
  display: flex;
}
.el-row-2{
  display: flex;
  align-items: center;
  justify-content: center;
}
  .title{
    width: 130px;
    height: 18px;
    font-size: 18px;
    font-weight: 700;
    color: #1f1f1f;
    line-height: 18px;
  }
  .font-common{
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
  }
  .font-money{
    font-size: 12px;
    color: #25293d;
    line-height: 12px;
  }
  .font-norm{
    font-size: 12px;
    color: #bdc0cb;
    line-height: 12px;
  }
  .grid-content{
    border-radius: 4px;
    min-height: 36px;
  }
  .bg-purple{
    border-radius: 8px;
    border: 1px solid #e0e2e8;
}
  .el-col-1{
    display: flex;
    justify-content:space-between;
    padding: 14px 46px;
  }
.el-col-2{
  display: flex;
  flex-direction: column;
  text-align: center;
}
.el-col-3{
  text-align: center;
  display: flex;
  padding: 14px 50px;
}
.el-col-6 {
  padding-left: 10px;
  padding-right: 10px;
  width: 25%;
}
.el-col{
  border-radius: 4px;
}
  div{
    display:block;
  }
  .button-font{
    width: 64px;
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #b88141;
    line-height: 16px;
  }
  .button-css{
    width: 450px;
    height: 48px;
    background: linear-gradient(96deg,#f7d9b7 0%, #eabf96 100%);
    border-radius: 4px;
  }
  .hr-1{
    background: #edf1f7;
    margin-left: 14px;
    margin-right: 14px;
  }
  .checked-div{
    border-color: #CE9B63;
  }
</style>
